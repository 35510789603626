<template>
  <v-dialog :value="dialog" max-width="500">
    <v-card class="modal-alert">
      <div class="modal-cover">
        <img
          src="img/cover-modal.png"
          width="100%"
          height="100%"
          alt="cover-full-plan"
        />
      </div>

      <v-card-text class="modal-content">
        <h2>{{ $t('modal_full_plan.title') }}</h2>

        <article>
          {{ $t('modal_full_plan.message') }}
        </article>
      </v-card-text>

      <v-card-actions class="modal-footer">
        <v-btn @click="close">
          {{ $t('modal_full_plan.actions.config_later') }}
        </v-btn>
        <v-btn class="config" color="#39AF49" @click="openConfigArea">
          {{ $t('modal_full_plan.actions.config_now') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <modal-config-area
      v-if="dialogConfigArea"
      :dialog="dialogConfigArea"
      @close="closeModalConfigArea"
    />
  </v-dialog>
</template>

<script>
const ModalConfigArea = () =>
  import('@/modules/menu/components/modals/ModalConfigArea.vue')

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ModalConfigArea,
  },

  data() {
    return {
      dialogConfigArea: false,
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    openConfigArea() {
      this.close()
      this.dialogConfigArea = true
    },

    closeModalConfigArea() {
      this.dialogConfigArea = false
    },
  },
}
</script>

<style scoped>
.modal-alert {
  border-radius: 8px !important;
}

.modal-alert .modal-content {
  padding: 32px !important;
}

.modal-alert .modal-content h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #1a2b46;
  text-align: center;
}
.modal-alert .modal-content article {
  font-size: 16px;
  font-weight: 400;
  color: #5b6459;
  text-align: center;
  margin-top: 8px;
}

.modal-footer {
  padding: 16px 24px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e6e9e6;
}
.modal-footer button {
  box-shadow: none;
  height: 40px !important;

  border: 1px solid #e6e9e6;
  padding: 16px !important;

  text-transform: capitalize;
  font-size: 14px;
  color: #1a2b46;
  font-weight: 500;
}
.modal-footer button.config {
  color: #fff;
}

@media (max-width: 425px) {
  .modal-cover {
    height: 86px;
  }

  .modal-content {
    padding: 24px !important;
  }

  .modal-footer {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .modal-footer button {
    width: 100%;
  }
}
</style>
